import { Box } from 'grommet'
import React from 'react'
import { useParams } from "react-router-dom"
import { useDocument } from "../../../hooks/useDocument"


// project components
import ProjectComments from './ProjectComments'
import ProjectSummary from './ProjectSummary'

export default function Project() {
    const { id } = useParams()
    const { error, document } = useDocument('projects', id)
    

    if (error) {
        return <div className="error">{error}</div>
    }
    if(!document) {
        return <div className="loading">Loading...</div>
    }

    

    return (
        <Box direction='row'  justify='between' pad='large'>
            <Box width='large'>
                <ProjectSummary project={document} />
            </Box>
    
            <Box width='large'>
                <ProjectComments project={document} />
            </Box>
        </Box>
    )
}
