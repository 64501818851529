import React from 'react'

import {
    Box
} from 'grommet'

// Newsletter component import
import NewsletterList from './NewsletterList'

export default function AdminNewsletter() {
    return (
        <Box direction='column' gap="medium" align="center" justify="center" fill pad="large" border>
            <NewsletterList />
        </Box>   
    )
}
