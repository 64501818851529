import { useState, useEffect } from 'react'
import { projectAuth, projectFirestore } from '../firebase/config'
import { useAuthContext } from './useAuthContext'

export const useSignup = () => {
  const [isCancelled, setIsCancelled] = useState(false)
  const [error, setError] = useState(null)
  const [isPending, setIsPending] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false) // State for success
  const [isError, setIsError] = useState(false) // State for error
  const { dispatch } = useAuthContext()

  const signup = async (displayName, email, password) => {
    setError(null)
    setIsPending(true)
  
    try {
      // signup
      const res = await projectAuth.createUserWithEmailAndPassword(email, password)

      if (!res) {
        throw new Error('Could not complete signup')
      }
    

      // add display name to user
      await res.user.updateProfile({ displayName })

      // create a user document 
      await projectFirestore.collection('users').doc(res.user.uid).set({
        online: false,
        displayName,
      })

       // dispatch login action
      dispatch({ type: 'LOGIN', payload: res.user })

      // update state
        setIsSuccess(true); // Set isSuccess to true on successful signup
        setIsPending(false);
        setError(null);
        return { success: true, user: res.user }; // return the user information after successful signup
      } catch (err) {
        setError(err.message);
        setIsError(true); // Set isError to true on Signup error
        setIsPending(false);
        return { success: false, error: err.message }; // return the error message on signup failure
      }
  };

  const closeSuccessModal = () => {
    setIsSuccess(false);
  };

  const closeErrorModal = () => {
    setIsError(false);
  };

  useEffect(() => {
    return () => setIsCancelled(true)
  }, [])

  return { signup, error, isPending, isSuccess, isError, closeSuccessModal, closeErrorModal  }
}