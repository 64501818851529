import {
    Box,
} from 'grommet'

import ProjectList from './ProjectList'

export default function Dashboard() {

    return (
        <Box align='center' alignContent='center' alignSelf='center'>
            
            <ProjectList/>
            
        </Box>
        



    )
}
