import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBuX9M4DQqxWb1CvVnp724fghVIxsqCF7c",
    authDomain: "stranahan-fund.firebaseapp.com",
    projectId: "stranahan-fund",
    storageBucket: "stranahan-fund.appspot.com",
    messagingSenderId: "619806662780",
    appId: "1:619806662780:web:7d055e20e46a203000a9f9",
    measurementId: "G-09GGV22CB3"
  };

// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()

// timestamp
const timestamp = firebase.firestore.Timestamp

export { projectFirestore, projectAuth, projectStorage, timestamp }