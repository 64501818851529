import { useState, useEffect, useRef, useContext  } from 'react';
import { Box, Heading, Form, FormField, TextInput, TextArea, Button, Text, FileInput, Anchor } from 'grommet';

import { AuthContext } from '../../../context/AuthContext'

// components
import SuccessMessageModal from '../../../components/SuccessMessageModal';
import ErrorMessageModal from '../../../components/ErrorMessageModal';
import EventDate from './EventDate';

// firebase hooks
import { useFirestore } from '../../../hooks/useFirestore'
import { useFeaturedImage } from '../../../hooks/useFeaturedImage';

export default function AdminEvents() {
  // form field values
  const [headline, setHeadline] = useState('');
  const [message, setMessage] = useState('');
  const [additionalLink, setAdditionalLink] = useState('');
  const [featuredImage, setFeaturedImage] = useState('');
  const [featuredError, setFeaturedError] = useState('');
  const [error, setError] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [selectedDateOption, setSelectedDateOption] = useState('One Date/Deadline');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [oneDateError, setOneDateError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('')
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [featuredImageError, setFeaturedImageError] = useState('');
  const [isLoading, setIsLoading] = useState(false)

  // user
  const { user } = useContext(AuthContext);

  // database
  const { addDocument, response } = useFirestore('events');
  const { uploadImage } = useFeaturedImage();

  const fileInputRef = useRef(null);

  const validateTimePeriod = (start, end) => {
    const startDateObj = new Date(start);
    const endDateObj = new Date(end);
    if (startDateObj > endDateObj) {
      setIsLoading(false); // Move this line here
      return "Start date cannot be after end date.";
    }
    return ''; // No error
  };
  
  const validateOneDate = (date) => {
    const dateObj = new Date(date);
    if (isNaN(dateObj)) {
      setIsLoading(false); // Move this line here
      return 'Invalid date. Please enter a valid date.';
    }
    return ''; // No error
  };

  const handleChangeDate = (value, field) => {
    if (field === 'startDate') {
      setStartDate(value);
      // Reset oneDateError when the startDate changes
      setOneDateError('');
    } else if (field === 'endDate') {
      setEndDate(value);
      // Reset error when the endDate changes
      setError('');
    } else if (field === 'selectedDateOption') {
      setSelectedDateOption(value);
      // Reset both errors when the selectedDateOption changes
      setError('');
      setOneDateError('');
    }
  };

  const resetFeaturedImageError = () => {
    setFeaturedImageError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

  setIsLoading(true);

  // Validate the Time Period
  const timePeriodError = validateTimePeriod(startDate, endDate);
  if (timePeriodError) {
    setError(timePeriodError);
    setIsLoading(false);
    return;
  }

  // Validate One Date/Deadline
  if (selectedDateOption === 'One Date/Deadline') {
    const oneDateError = validateOneDate(startDate);
    if (oneDateError) {
      setOneDateError(oneDateError);
      setError('');
      setIsLoading(false);
      return;
    }
  }

  // Validate the featuredImage
  if (!featuredImage) {
    setFeaturedImageError('Please select a featured image.');
    setIsLoading(false);
    return;
  }

  // Reset error state
  setError('');

    if (error) {
      console.log('Cannot submit. Please fix the errors.');
      setIsLoading(false)
      return;
    }

    // Creaetd By
    const createdBy = {
      displayName: user.displayName,
      id: user.uid
    }

    // Prepare the event object to be posted
    const event = {
      headline,
      date: selectedDateOption === 'No Date' ? '' : selectedDateOption === 'One Date/Deadline' ? startDate : `${startDate} to ${endDate}`,
      message,
      additionalLink,
      featuredImage,
      createdBy
    };

    try {
      // Upload the featured image and get the image URL
      if (featuredImage) {
        const imageUrl = await uploadImage(featuredImage);
        event.featuredImage = imageUrl; // Add the image URL to the event object
      }

      // Adding the event document to the database (events collection)
      await addDocument(event);
      // If there's no error, show the success modal and clear the form fields
        handleReset();
        setShowSuccessModal(true);
        setIsLoading(false);
      } catch (err) {
        console.error('Error adding event document:', err);
        setErrorMessage('An error occurred while submitting the form. Please try again later.');
        setShowErrorModal(true);
        setIsLoading(false);
      }}


  const handleReset = () => {
    // Empty form fields
    setHeadline('');
    setMessage('');
    setAdditionalLink('');
    setFeaturedImage(null);
    setError('');
    setSelectedDateOption('One Date/Deadline');
    setStartDate('');
    setEndDate('');
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Clear the selected file
    }
  };



  // isError Listener
  useEffect(() => {
    if (error) {
      setShowErrorModal(true);
    }
  }, [error]);

  // Function to Close Error Modal
  const closeErrorModal = () => {
    setShowErrorModal(false);
  };

  // Function close Success Modal
  const closeSuccessModal = () => {
    setShowSuccessModal(false);
  }

  const handleFileChange = (e) => {
  const file = e.target.files[0];
  const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];

  // Check if a file is selected
  if (!file) {
    setFeaturedImage(null);
    resetFeaturedImageError();
    return;
  }

  // Check if the selected file is an image
  if (!allowedTypes.includes(file.type)) {
    setFeaturedImage(null);
    setFeaturedImageError('Please select a valid image (JPEG, PNG, or GIF).');
    return;
  }

  setFeaturedImage(file);
  resetFeaturedImageError();
};



  return (
      <Box width="xlarge" pad="medium" round background="light-1" elevation="medium">
        <Heading level={3} alignSelf="center">
          Create New Event
        </Heading>
        <Form onSubmit={handleSubmit} onReset={handleReset}>
          <FormField label="Headline">
            <TextInput placeholder="Enter headline here" value={headline} onChange={(event) => setHeadline(event.target.value)} required/>
          </FormField>
          {/* EventDate.js */}
          <EventDate 
            selectedDateOption={selectedDateOption}
            handleChangeDate={handleChangeDate}
            startDate={startDate}
            endDate={endDate}
            error={error}
            oneDateError={oneDateError}
          />
          <FormField label="Message">
            <TextArea placeholder="Type your message here" value={message} onChange={(event) => setMessage(event.target.value)} required />
          </FormField>
          <FormField label={<Box direction="row" gap="xsmall" align="center"><Text>Additional Link</Text> <Text color="status-critical">(not required)</Text></Box>}>
            <TextInput placeholder="https://www.example.com" value={additionalLink} onChange={(event) => setAdditionalLink(event.target.value)} />
          </FormField>
          <FormField name="image" htmlFor='image-upload' label="Featured Image">
            <FileInput aria-label="Choose files" ref={fileInputRef} type="file" onChange={handleFileChange} required/>
            {featuredError && <Box><Text color="status-critical">{featuredError}</Text></Box>}
          </FormField>
            <Text>If you cant don't have an image feel free to find one on {<Anchor label="https://www.manypixels.co/gallery" href={"https://www.manypixels.co/gallery"} target="_blank" />}</Text>
          {!isLoading && (
            <Box direction="row" justify="end" gap="medium">
              <Button type="reset" label="Reset" />
              <Button type="submit" label="Save" primary />
            </Box>
          )}
          {isLoading && (
            <Box direction="row" justify="end" gap="medium">
            <Button type="reset" disabled label="Reset" />
            <Button type="submit" disabled label="Loading..." primary />
          </Box>
          )}
          
          {/* Success Modal */}
          {showSuccessModal && (
            <SuccessMessageModal message={successMessage} onClose={closeSuccessModal} />
          )}
          {/* Error Modal */}
          {showErrorModal && <ErrorMessageModal message={error} onClose={closeErrorModal} />}
        </Form>
      </Box>
  );
}
