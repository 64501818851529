import { Box } from "grommet"

// Contact component imports
import ContactList from './ContactList'


export default function AdminContact() {
    return (
        <Box direction='column' gap="medium" align="center" justify="center" fill pad="large" border>
            <ContactList />
        </Box>    
    )
}
