import { useState } from "react"
import { timestamp } from "../../../firebase/config"
import { useAuthContext } from "../../../hooks/useAuthContext"
import { v4 as uuidv4 } from 'uuid'
import { useFirestore } from "../../../hooks/useFirestore"
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import { 
    Box,
    FormField,
    Heading,
    Text,
    TextArea,
    Form,
    Button
} from "grommet"

export default function ProjectComments({ project }) {
    const { updateDocument, response } = useFirestore('projects')
    const [newComment, setNewComment] = useState('')
    const { user } = useAuthContext()
    const uniqueId = uuidv4();

    const handleSubmit = async (e) => {
        e.preventDefault()

        const commentToAdd = {
            displayName: user.displayName,
            content: newComment,
            createdAt: timestamp.fromDate(new Date()),
            id: uniqueId
        }
        
        await updateDocument(project.id, {
            comments: [...project.comments, commentToAdd] // adds the previous comments already posted and the new one that is being updated
        })
        
        if (!response.error) {
            setNewComment('')
        }
    }

    return (
        <Box pad='large' width='large'>
            <Heading level={3} alignSelf='start' margin={{ bottom: 'medium' }}>
                Project Comments
            </Heading>
            <Box>
            <ul>
                {project.comments.length > 0 &&
                    project.comments.map((comment) => (
                    <li key={comment.id}>
                        <Box direction='row' align='center' gap='small'>
                        <Text weight='bold'>{comment.displayName}</Text>
                        <Text size='small' >
                            {formatDistanceToNow(comment.createdAt.toDate(), { addSuffix: true })}
                        </Text>
                        </Box>
                        <Text>{comment.content}</Text>
                    </li>
                    ))}
                </ul>


                <Form onSubmit={handleSubmit} margin={{ top: 'medium' }}>
                <FormField label='Add new comment:'>
                    <TextArea required onChange={(e) => setNewComment(e.target.value)} value={newComment} />
                </FormField>
                <Button type='submit' color="brand" hoverIndicator="control"  primary label='Add comment' margin={{ top: 'small' }} alignSelf='end' />
                </Form>
            </Box>
        </Box>
    )
}
