import React from 'react'
import {
    Box,
} from "grommet"

// Event component imports
import AdminEvents from './AdminEvents'
import EventList from './EventList'

export default function EventAdminPage() {
    return (
            <Box direction='column' gap="medium" align="center" justify="center" fill pad="large" border>
                <AdminEvents />
                <EventList />                
        </Box>
    )
}
