import { Box, Button, Heading, Text } from 'grommet'
import React from 'react'
import { useFirestore } from '../../../hooks/useFirestore'
import { useHistory } from 'react-router-dom'
import { Previous, Checkmark } from 'grommet-icons'


export default function ProjectSummary({project}) {
    const { deleteDocument } = useFirestore('projects')
    const history = useHistory()
    
    const handleClick = (e) => {
        deleteDocument(project.id)
        history.push('/dashboard')
    }

    const handleBack = () => {
        history.push('/dashboard')
    }

    return (
        <Box elevation='medium' round background='light-1' pad='large' margin='large' width='large'>
            <Button color="brand" hoverIndicator="control" alignSelf='start'  icon={<Previous/>} label="Back to dashboard" onClick={handleBack} />
            <Heading level={3} margin={{ bottom: 'medium' }}>
                {project.projectName}
            </Heading>
            
            <Text weight='bold' margin={{ bottom: 'medium' }}>
                Project due by {project.projectDueDate.toDate().toDateString()}
            </Text>
            <Text>{project.projectDetails}</Text>
            <Box margin={{ top: 'medium' }}>
                <Text weight='bold' margin={{ bottom: 'small' }}>
                Project is assigned to:
                </Text>
                <Box pad='medium' border='all' round='small'>
                    <ul style={{ listStyleType: 'disc', marginLeft: '20px', paddingInlineStart: '20px' }}>
                        {project.assignedUsersList.map((user) => (
                        <li key={user.id}>
                            <Text>{user.displayName}</Text>
                        </li>
                        ))}
                    </ul>
                </Box>
                <Button color="brand" hoverIndicator="control" alignSelf='start' primary onClick={handleClick} icon={<Checkmark/>} label='Mark as complete' margin={{ top: 'medium' }} />
            </Box>
        </Box>
    )
}
