import { useState } from 'react'
import {
    Form,
    Button,
    Heading,
    FormField,
    TextInput,
    Text,
    Layer
} from 'grommet'
import {Box} from 'grommet'
import {useSignup} from '../../hooks/useSignup'
import SuccessMessageModal from '../../components/SuccessMessageModal'
import ErrorMessageModal from '../../components/ErrorMessageModal'
import { HelpOption } from 'grommet-icons';

export default function Signup() {
    const [displayName, setDisplayName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { signup, isPending, error, isSuccess, isError, closeSuccessModal, closeErrorModal } = useSignup()
    const [showInfo, setShowInfo] = useState(false);

    
    const handleSubmit = async (e) => {
        e.preventDefault()
        signup(displayName, email, password)
        .then((result) => {
            if (result.success) {
            // If signup is successful, reset the form fields
            setDisplayName('');
            setEmail('');
            setPassword('');
            }
        });
    }


    const handleCloseInfo = () => {
        setShowInfo(false);
    };

    return (
        <Box a align='center' justify='center' fill pad="large" border>
            <Box width="medium" pad="medium" round background="light-1" elevation="medium">
            <Heading level={3} alignSelf='center'>Sign Up an Admin</Heading>
                <Form  onSubmit={handleSubmit}>
                    {/* admin display name */}
                    <FormField label="Name" name="name" required>
                        <TextInput 
                            aria-label="name" 
                            placeholder="Enter First Name"
                            name="name" 
                            value={displayName} 
                            type="name"
                            onChange={(e) => setDisplayName(e.target.value)}
                        />
                    </FormField>
                    {/* admin display email Address */}
                    <FormField label="Email" name="email" required>
                        <TextInput 
                            placeholder="Enter Email"
                            aria-label="email" 
                            name="email" 
                            value={email} 
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </FormField>
                    {/* admin display Password */}
                    <FormField label="Password" name="password" required>
                        <TextInput
                            placeholder="Enter Password"
                            aria-label="password" 
                            name="password" 
                            value={password} 
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </FormField>
                    {/* Admin Submit Button */}
                    <Box direction="row" alignSelf='center' align='center'>
                        {!isPending && <Button color='brand' pad="small" hoverIndicator="control" label="Submit" type="submit"/>}
                        {isPending && <Button color='brand' pad="small" hoverIndicator="control" label="Loading..." disabled/>} 
                        <Box direction='row' margin={{left: "xlarge"}} align='center'>
                        <Button
                        icon={<HelpOption />}
                        onClick={() => setShowInfo(true)} // Set showInfo to true when the Button is clicked
                        />
                        <Text weight="bold">Click Me</Text>
                        </Box>
                        
                        {showInfo && (
                        <Layer
                            position='center'
                            modal
                            onClickOutside={handleCloseInfo}
                            onEsc={handleCloseInfo}
                        >
                            <Box width="large" border pad="large" gap="small"> 
                                <Text size='medium' textAlign='start'>
                                If you sign up a user, that user will automatically be signed in. Please
                                logout and login to your own account.
                                </Text>                    
                                <Button alignSelf='end' color='brand' pad="small" hoverIndicator="control" label="Okay" onClick={handleCloseInfo}/>
                            </Box>
                            
                        </Layer>
                        )}
                    </Box>
                    

                </Form>
                {isSuccess && (
                    <SuccessMessageModal
                    message="Signup successful!"
                    onClose={closeSuccessModal}
                    />
                )}
                {/* Show the error modal when isError is true */}
                {isError && (
                    <ErrorMessageModal
                    message={error}
                    onClose={closeErrorModal}
                    />
                )} 
            </Box>
        </Box>
        
    )
}
