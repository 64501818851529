import { 
    Box,
    Heading,
    Button,
    Text,
    Spinner,
    Anchor
} from 'grommet'
import React, { useState } from 'react'

// firebase
import { useFirestore } from '../../../hooks/useFirestore'
import { useCollection } from '../../../hooks/useCollection';

// modals
import SuccessMessage from '../../../components/SuccessMessage';
import ErrorMessage from '../../../components/ErrorMessage';
import UpdateModal from '../../../components/updateModal';

export default function ScholarshipLink() {
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [showUpdateSuccess, setShowUpdateSuccess] = useState(false);
    const [showUpdateError, setShowUpdateError] = useState(false);
    const [selectedScholarshipLink, setSelectedScholarshipLink] = useState(null)

    const { updateDocument } = useFirestore('scholarshipLinks')
    const { documents: scholarshipLinks, loading } = useCollection('scholarshipLinks')
    
    // Handle update
    const handleUpdate = async (scholarshipLinkData) => {
        try {
            await updateDocument(selectedScholarshipLink.id, scholarshipLinkData);
            setUpdateModalOpen(false);
            setSelectedScholarshipLink(null);
            setShowUpdateSuccess(true);
            // Hide the success message after 3 seconds
            setTimeout(() => {
                setShowUpdateSuccess(false);
            }, 3000);
        } catch (error) {
            setShowUpdateError(true);
            // Hide the error message after 3 seconds
            setTimeout(() => {
                setShowUpdateError(false)
            }, 3000);
        }
    };

    return (
        <Box width="xlarge" pad="medium" round background="light-1" elevation="medium">
            <Box pad={{bottom: 'medium'}}>

            <Heading level={3}  alignSelf='center'>Edit Scholarship Link</Heading>
            
            <Box alignSelf='center' margin={{bottom: "medium"}} direction='column' align='center'>
            <Text>This is where you will find the link for the google form. If decided you can just add this link instead of the form:</Text>
            <Anchor alignSelf='center' label={<Text>https://browardedfoundation.org/apply/</Text>} href="https://browardedfoundation.org/apply/" target='_blank'/>
            </Box>
            <Text textAlign='center'>Only update once a year when Broward Education Foundation gets a opens a new Google form scholarship application</Text>
            
            </Box>
            {loading && (
                <Box align="center" justify="center" fill>
                    <Spinner size="large"/>
                </Box>
            )}
            {!loading && scholarshipLinks && scholarshipLinks.length > 0 && (
                <>
                {scholarshipLinks.map((scholarshipLink, index) => (
                    <Box key={index} direction='column' gap="medium">
                    <Anchor
                        href={scholarshipLink.link} target="_blank"
                        alignSelf='center'
                        label={<Text>{scholarshipLink.link}</Text>}
                    />
                    <Box align='center'>
                    <Button onClick={() => {
                        setSelectedScholarshipLink(scholarshipLink);
                        setUpdateModalOpen(true);
                    }} primary color="brand" hoverIndicator="control" label={<Text>Update</Text>}/>
                    </Box>
                    </Box>
                    
                ))}
                </>
            )}
            {!loading && (!scholarshipLinks || scholarshipLinks.length === 0) && (
                <Box align="center" margin="medium">
                <Text>No scholarship Link available.</Text>
                </Box>
            )}

            {/* Update Modal */}
            {updateModalOpen && (
                    <UpdateModal
                    data={selectedScholarshipLink}
                    onUpdate={handleUpdate}
                    onClose={() => setUpdateModalOpen(false)}
                    fields={[
                        { name: 'link', label: 'Change the Scholarship Link', type: 'text' },
                        
                    ]}
                    />
                )}
                {/* Update success message */}
                {showUpdateSuccess && <SuccessMessage message="Scholarship Link successfully updated!" />}
                {/* Update error message */}
                {showUpdateError && <ErrorMessage message="Error occurred while updating." />}

        </Box>
    )
}
