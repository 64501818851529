import React from 'react'
import { 
  Box 
} from 'grommet'

// Scholarship component imports
import ScholarshipsCreate from './ScholarshipsCreate'
import ScholarshipList from './ScholarshipList'
import ScholarshipLink from './ScholarshipLink'

export default function AdminScholarship() {
  return (
    <Box direction='column' gap="medium" align="center" justify="center" fill pad="large" border >
      <ScholarshipsCreate />
      <ScholarshipList />
      <ScholarshipLink/>
    </Box>
  )
}
