import React, { useState, useEffect } from 'react'
import {
    Box,
    TextInput,
    Form,
    FormField,
    Heading,
    TextArea,
    Text,
    Button
} from 'grommet'
import { useHistory } from 'react-router-dom'
import { useCollection } from '../../../hooks/useCollection'
import { useFirestore } from '../../../hooks/useFirestore'
import { useAuthContext } from '../../../hooks/useAuthContext'
import { timestamp } from '../../../firebase/config'
import Select from 'react-select'
import { Previous } from 'grommet-icons'

export default function CreateProject() {
    const history = useHistory()
    const { addDocument, response } = useFirestore('projects')
    const { documents } = useCollection('users')
    const [users, setUsers] = useState([])
    const { user } = useAuthContext();
    const [formError, setFormError] = useState(null)

    // form field values
    const [projectName, setProjectName] = useState('')
    const [projectDetails, setProjectDetails] = useState('')
    const [projectDueDate, setProjectDueDate] = useState('')
    const [assignedUsers, setAssignedUsers] = useState([])

    useEffect(() => { // this useEffect creates an array that looks something like the categories array above but for users
        if(documents) {
            const options = documents.map(user => {
                return { value: user, label: user.displayName}
            })
            setUsers(options)
            }
        }, [documents])
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        setFormError(null) // resets the error variable to make sure there is no pre-existing errors

        if (assignedUsers.length < 1) {
            setFormError('Please assign the project to at least 1 user')
            return
        }

        const createdBy = {
            displayName: user.displayName,
            id: user.uid
        }

        const assignedUsersList = assignedUsers.map((u) => {
            return {
                displayName: u.value.displayName,
                id: u.value.id
            }
        })
        const dueDate = new Date(projectDueDate);
        const utcDueDate = new Date(dueDate.getUTCFullYear(), dueDate.getUTCMonth(), dueDate.getUTCDate());
        const project = {
            projectName,
            projectDetails,
            projectDueDate: timestamp.fromDate(utcDueDate),
            comments: [],
            createdBy,
            assignedUsersList
        }

        // Add document to database (projects)
        await addDocument(project)
        if(!response.error) {
            history.push('/dashboard')
        }
    }

    const handleBack = () => {
        history.push('/dashboard')
    }

    return (
        <Box margin="large" pad="medium" alignSelf='center' width="xlarge"  round background="light-1" elevation="medium">
            <Button alignSelf='start' icon={<Previous/>} label="Back to dashboard" onClick={handleBack} />
            <Heading level={3} alignSelf="center">
            Create New Project
            </Heading>
            <Form onSubmit={handleSubmit}>
                <FormField label="Project name:" >
                    <TextInput
                        value={projectName}
                        onChange={(e) => setProjectName(e.target.value)}
                        required
                        placeholder="Enter project name"
                    />
                </FormField>
                <FormField label="Project details:">
                    <TextArea 
                        required
                        type="text"
                        placeholder='Enter project details'
                        onChange={(e) => setProjectDetails(e.target.value)}
                        value={projectDetails}
                        resize={false}
                    />
                </FormField>
                <FormField label="Set due date:">
                    <TextInput
                        required
                        type="date"
                        onChange={(e) => setProjectDueDate(e.target.value)}
                        value={projectDueDate}
                    />
                </FormField>
                <FormField label="Assign to">
                    <Select 
                    onChange={(option) => setAssignedUsers(option)}
                    options={users}
                    isMulti
                    />
                </FormField>
                <Button margin={{vertical: "medium"}} hoverIndicator="brand" type='submit' label={<Text weight="bold">Add Project</Text>}/>

                {formError && <Text>{formError}</Text>}
            </Form>
        </Box>
    )
}
