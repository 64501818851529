import React, { useState } from 'react';
import { Box, Heading, Text, Button, DataTable, Spinner } from 'grommet';
import { Trash } from 'grommet-icons';
import { formatRelative } from 'date-fns';

// Modals
import DeleteModal from '../../../components/deleteModal'
import SuccessMessage from '../../../components/SuccessMessage';
import ErrorMessage from '../../../components/ErrorMessage';

// Firebase
import { useFirestore } from '../../../hooks/useFirestore';
import { useCollection } from '../../../hooks/useCollection';

export default function ContactList() {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const { deleteDocument } = useFirestore('contacts')
    const [trashHoverStates, setTrashHoverStates] = useState({});
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    // Firestore hook for fetching contacts
    const { documents: messages, loading } = useCollection('contacts')   

    // Sort the messages array based on the date in descending order (most recent at the top)
    const sortedMessages = messages ? [...messages].sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate()) : [];



    const handleDeleteMessage = (messageId) => {
        const messageToDelete = messages.find((message) => message.id === messageId);
        setSelectedMessage(messageToDelete);
        setShowDeleteModal(true);
    };

    const onDelete = async() => {
        // Delete the selected message
        if (selectedMessage) {
            try {
                await deleteDocument(selectedMessage.id);
                // Show the success message
                setShowSuccessMessage(true);
                // Hide the success message after 3 seconds
                setTimeout(() => {
                    setShowSuccessMessage(false);
                }, 3000);
            } catch (error) {
                // Show the error message
                setShowErrorMessage(true);
                // Hide the error message after 3 seconds
                setTimeout(() => {
                    setShowErrorMessage(false);
                }, 3000);
            }
        }
        // Close the modal
        setShowDeleteModal(false);
    }

    // Transform messages into an array of objects suitable for DataTable
    const dataTableData = sortedMessages.map((message) => ({
        id: message.id,
        name: message.name,
        email: message.email,
        message: message.message,
        date: formatRelative(message.createdAt.toDate(), new Date())
    }))

    const columns = [
        {
            property: 'name',
            header: <Text weight="bold">Name</Text>,
            primary: true,
            align: 'center'
        },
        {
            property: 'email',
            header: <Text weight="bold">Email</Text>,
            render: (row) => (
                <a href={`mailto:${row.email}`}>{row.email}</a>
            ),
            align: 'center'
        },
        {
            property: 'message',
            header: <Text weight="bold">Message</Text>,
            align: 'center'
        },
        {
            property: 'date',
            header: <Text weight="bold">Date</Text>,
            align: 'center'
        },
        {
            property: 'actions',
            header: <Text weight="bold">Actions</Text>,
            align: 'center',
            sortable: false,
            render: (row) => (
                <Button 
                    alignSelf='center'
                    plain
                    onMouseOver={() => setTrashHoverStates((prevStates) => ({ ...prevStates, [row.id]: true }))}
                    onMouseOut={() => setTrashHoverStates((prevStates) => ({ ...prevStates, [row.id]: false }))}
                    onClick={() => handleDeleteMessage(row.id)}
                >
                    <Trash color={trashHoverStates[row.id] ? 'status-critical' : undefined} />
                </Button>
            )
        }
    ]
    

    return (
        <Box width="xlarge" pad="medium" round background="light-1" elevation="medium">
            <Heading  level={3}  alignSelf='center'>Contact List</Heading>
                <Box align="center" pad="large">
                {loading && (
                <Box align="center" justify="center" fill>
                <Spinner size="large" />
                </Box>
                )}

                {!loading && messages && messages.length > 0 && (
                    <DataTable
                    columns={columns}
                    data={dataTableData}
                    sortable
                    primaryKey="id"
                    step={10}
                    paginate
                    pad="small"
                />
                )}

                {!loading && (!messages || messages.length === 0) && (
                    <Box align="center" margin="medium">
                    <Text>No messages available.</Text>
                    </Box>
                )}
                
                {/* Modal for delete confirmation */}
                {showDeleteModal && selectedMessage && (
                    <DeleteModal name={selectedMessage.name} onDelete={onDelete} onClose={() => setShowDeleteModal(false)}/>
                )}
                {/* Success message */}
                {showSuccessMessage && <SuccessMessage message={"Successfully deleted!"} />}

                    {/* Error message */}
                {showErrorMessage && <ErrorMessage message={"Error occurred while deleting."}/>}
                </Box>
        
        </Box>
    );
}
