import {useState} from 'react'
import {
    Box, 
    Heading,
    Accordion,
    AccordionPanel,
    Text,
    Image,
    Stack,
    Button,
    Anchor,
    Spinner
} from 'grommet'
import { Trash, Save } from "grommet-icons"
import { formatRelative, isBefore  } from 'date-fns'

// modals
import UpdateModal from '../../../components/updateModal';
import DeleteModal from '../../../components/deleteModal';
import SuccessMessage from '../../../components/SuccessMessage';
import ErrorMessage from '../../../components/ErrorMessage';

// firestore
import { useFirestore } from '../../../hooks/useFirestore'
import { useCollection } from '../../../hooks/useCollection';

export default function EventList() {
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [showUpdateSuccess, setShowUpdateSuccess] = useState(false);
    const [showUpdateError, setShowUpdateError] = useState(false);
    const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
    const [showDeleteError, setShowDeleteError] = useState(false);
    const { updateDocument, deleteDocument } = useFirestore('events');
    const currentDate = new Date();

    // Firestore hook for fetching events
    const { documents: events, loading } = useCollection('events')  

    // Handle Update
    const handleUpdate = async (eventData) => {
        try {
            await updateDocument(eventData.id, eventData);
            setUpdateModalOpen(false); // Close the UpdateModal
            setSelectedEvent(null); // Reset the selected event
            setShowUpdateSuccess(true); // Show the success message
            // Hide the success message after 3 seconds
            setTimeout(() => {
                setShowUpdateSuccess(false);
            }, 3000);
            } catch (error) {
                setShowUpdateError(true); // Show the error message
            // Hide the error message after 3 seconds
            setTimeout(() => {
                setShowUpdateError(false);
            }, 3000);
            }
        };

    // Handle Delete
    const handleDelete = async (eventId) => {
        try {
            await deleteDocument(eventId);
            setDeleteModalOpen(false); // Close the DeleteModal
            setSelectedEvent(null); // Reset the selected event
            setShowDeleteSuccess(true); // Show the delete success message
            // Hide the delete success message after 3 seconds
            setTimeout(() => {
                setShowDeleteSuccess(false);
            }, 3000);
            } catch (error) {
            setShowDeleteError(true); // Show the delete error message
            // Hide the delete error message after 3 seconds
            setTimeout(() => {
                setShowDeleteError(false);
            }, 3000);
        }
    };


    // Sort events by creation date in descending order
    const sortedEvents = events ? [...events].sort((a, b) =>  b.createdAt.toDate() - a.createdAt.toDate()) : [];

    return (
        <Box width="xlarge" pad="medium" round background="light-1" elevation="medium">
        <Heading level={3} alignSelf="center">
            Event List
        </Heading>
        <Box margin={{ top: 'medium' }} border={{ color: 'border', size: 'xsmall' }} >
            {loading && (
                <Box align="center" justify="center" fill>
                <Spinner size="large" />
                </Box>
            )}

            {!loading && events && events.length > 0 && (
                <Accordion multiple>
                {sortedEvents.map((event, index) => {
                    const eventDate = event.date ? event.date : null;
                    const isDatePast = eventDate && isBefore(new Date(eventDate), currentDate);
    
                    return (
                        <AccordionPanel
                        key={event.id}
                        label={
                            <Stack anchor="top-right">
                            <Box direction="column" pad="small" align="start" gap="small">
                                <Box direction="row" gap="small">
                                    <Text size="large">{index + 1}.)</Text>
                                    <Text size="large">{event.headline}</Text>
                                </Box>
                                {event.date ? (
                                <Box background={isDatePast ? 'status-critical' : 'brand'} pad={{ horizontal: 'xsmall', vertical: 'xxsmall' }} round="xsmall">
                                    <Text size="xsmall" color={isDatePast ? 'white' : 'text'}>Date of event: {event.date}</Text>
                                </Box>
                                ) : (
                                    <Box background='status-warning' pad={{ horizontal: 'xsmall', vertical: 'xxsmall' }} round="xsmall">
                                    <Text size="xsmall" >No Date Added</Text>
                                </Box>
                                )}
                                
                            </Box>
                            </Stack>
                        }
                        >
                        <Box background="light-2" pad="medium" round border={{ color: 'border', size: 'xsmall' }}>
                            <Text size="small">Created {formatRelative(event.createdAt.toDate(), new Date())} By {event.createdBy.displayName}</Text>
                            <Box margin={{ top: 'medium' }}>
                            <Box direction="row" align='center' gap="xsmall">
                                <Text weight="bold" width="100px">Message: </Text>
                                <Text>{event.message}</Text>
                            </Box>
                            
                            {event.featuredImage && <Image width="200px" height="auto" src={event.featuredImage} />}
                            {event.additionalLink && <Box direction="row" align='center' gap="xsmall"><Text weight="bold">Additional Links:</Text> <Anchor label={event.additionalLink} href={event.additionalLink} target="_blank"/></Box>}
                            </Box>
    
                            <Box direction="row" justify="end" gap="small" margin={{ top: 'medium' }}>
                                <Button
                                label={<Text weight="bold">Update</Text>}
                                icon={<Save />}
                                size="small"
                                primary
                                hoverIndicator="brand"
                                onClick={() => {
                                    setSelectedEvent(event);
                                    setUpdateModalOpen(true);
                                }}
                                />
                                <Button
                                label={<Text weight="bold">Delete</Text>}
                                icon={<Trash />}
                                color="status-critical"
                                hoverIndicator="status-critical"
                                size="small"
                                onClick={() => {
                                    setSelectedEvent(event);
                                    setDeleteModalOpen(true);
                                }}
                                />
                            </Box>
                        </Box>
                        </AccordionPanel>
                    )
                }
                    
                )}
                </Accordion>
            )}

            {!loading && (!events || events.length === 0) && (
                <Box align="center" margin="medium">
                <Text>No events available.</Text>
                </Box>
            )}
            
        </Box>

        {/* Update Modal */}
        {updateModalOpen && (
            <UpdateModal
            data={selectedEvent}
            onUpdate={handleUpdate}
            onClose={() => setUpdateModalOpen(false)}
            fields={[
                { name: 'headline', label: 'Headline', type: 'text' },
                { name: 'date', label: 'Date', type: 'text' },
                { name: 'message', label: 'Message', type: 'textarea' },
                { name: 'additionalLink', label: 'Additional Links', type: 'text'}
                // Add other fields as needed for updating
            ]}
            />
        )}
    
        {/* Delete Modal */}
        {deleteModalOpen && (
            <DeleteModal
            name={selectedEvent.headline} // or any other field that represents the name
            onDelete={() => handleDelete(selectedEvent.id)}
            onClose={() => setDeleteModalOpen(false)}
            />
        )}

        {/* Update success message */}
        {showUpdateSuccess && <SuccessMessage message="Event successfully updated!" />}
        {/* Update error message */}
        {showUpdateError && <ErrorMessage message="Error occurred while updating." />}
        {/* Delete success message */}
        {showDeleteSuccess && <SuccessMessage message="Event successfully deleted!" />}
        {/* Delete error message */}
        {showDeleteError && <ErrorMessage message="Error occurred while deleting." />}
    </Box>
    )
}
