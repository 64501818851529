import {useState} from 'react'
import {
    Box,
    Heading,
    DataTable,
    Text,
    Button,
    Spinner
} from 'grommet'
import { Trash, Edit } from 'grommet-icons';

// Modal
import UpdateModal from '../../../components/updateModal';
import DeleteModal from '../../../components/deleteModal';
import SuccessMessage from '../../../components/SuccessMessage';
import ErrorMessage from '../../../components/ErrorMessage';

// Firebase
import { useFirestore } from '../../../hooks/useFirestore';
import { useCollection } from '../../../hooks/useCollection';

export default function ScholarshipList() {
    // Hover action buttons
    const [trashHoverStates, setTrashHoverStates] = useState({});
    const [editHoverStates, setEditHoverStates] = useState({});
    // Modals
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [showUpdateSuccess, setShowUpdateSuccess] = useState(false);
    const [showUpdateError, setShowUpdateError] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    const [selectedWinner, setSelectedWinner] = useState(null);
    const { updateDocument, deleteDocument } = useFirestore('scholarshipWinners')

    // Firestore hook for fetching scholarship winners
    const { documents: winners, loading } = useCollection('scholarshipWinners')   
    


    // Sort the winners array based on the year (ascending order)
    const sortedWinners = winners ? [...winners].sort((a, b) => b.year - a.year) : [];


    // Transform winners into an array of objects suitable for DataTable
    const dataTableData = sortedWinners.map((winner) => ({
        id: winner.id,
        year: winner.year,
        winnerName: winner.winnerName,
        scholarshipName: winner.scholarshipName,
    }))

    // Handle update
    const handleUpdate = async (winnerData) => {
        try {
            await updateDocument(winnerData.id, winnerData);
            setUpdateModalOpen(false);
            setSelectedWinner(null);
            setShowUpdateSuccess(true);
            // Hide the success message after 3 seconds
            setTimeout(() => {
                setShowUpdateSuccess(false);
            }, 3000);
        } catch (error) {
            setShowUpdateError(false);
            // Hide the error message after 3 seconds
            setTimeout(() => {
                setShowUpdateError(false)
            }, 3000);
        }
    };

    // Handle delete
    const handleDeleteWinner = (winnerId) => {
        const winnerToDelete = winners.find((winner) => winner.id === winnerId);
        setSelectedWinner(winnerToDelete);
        setShowDeleteModal(true);
    };

    
    const onDelete = async() => {
        // Delete the selected message
        if (selectedWinner) {
            try {
                await deleteDocument(selectedWinner.id);
                // Show the success message
                setShowSuccessMessage(true);
                // Hide the success message after 3 seconds
                setTimeout(() => {
                    setShowSuccessMessage(false);
                }, 3000);
            } catch (error) {
                // Show the error message
                setShowErrorMessage(true);
                // Hide the error message after 3 seconds
                setTimeout(() => {
                    setShowErrorMessage(false);
                }, 3000);
            }
        }
        // Close the modal
        setShowDeleteModal(false);
    }

    const columns = [
        {
            property: 'year',
            header: <Text weight="bold">Year</Text>,
            primary: true,
            size: 'xsmall',
            align: 'center',
            
        },
        {
            property: 'winnerName',
            header: <Text  weight="bold">Winner's Name</Text>,
            size: 'medium',
            align: 'center'
        },
        {
            property: 'scholarshipName',
            header: <Text  weight="bold">Scholarship Name</Text>,
            size: 'medium',
            align: 'center'
        },
        {
            property: 'actions',
            header: <Text  weight="bold">Actions</Text>,
            sortable: false,
            size: 'medium',
            align: 'center',
            render: (row) => (
                <Box direction='row' justify='center' gap="small">
                    <Button
                    alignSelf='center'
                    plain
                    onMouseOver={() => setEditHoverStates((prevStates) => ({ ...prevStates, [row.id]: true }))}
                    onMouseOut={() => setEditHoverStates((prevStates) => ({ ...prevStates, [row.id]: false }))}
                    onClick={() => {
                        setSelectedWinner(row);
                        setUpdateModalOpen(true);
                    }}
                    >
                        <Edit color={editHoverStates[row.id] ? 'status-ok' : undefined}/>
                    </Button>
                    <Button 
                        alignSelf='center'
                        plain
                        onMouseOver={() => setTrashHoverStates((prevStates) => ({ ...prevStates, [row.id]: true }))}
                        onMouseOut={() => setTrashHoverStates((prevStates) => ({ ...prevStates, [row.id]: false }))}
                        onClick={() => handleDeleteWinner(row.id)}
                    >
                        <Trash color={trashHoverStates[row.id] ? 'status-critical' : undefined} />
                    </Button>
                </Box>
                
            )
        },
    ];
        
    return (
        <Box width="xlarge" pad="medium" round background="light-1" elevation="medium">
            <Heading  level={3} alignSelf='center'>Scholarship List</Heading>
        

            <Box align="center" pad="large">
            {loading && (
                <Box align="center" justify="center" fill>
                <Spinner size="large" />
                </Box>
            )}

            {!loading && winners && winners.length > 0 && (
                <DataTable
                columns={columns}
                data={dataTableData}
                primaryKey="id"
                pad="small"
                sortable
                background={{
                    header: 'light-3',
                    body: ['light-2', 'light-1'],
                    footer: 'light-3',
                    }}
                />
            )}

            {!loading && (!winners || winners.length === 0) && (
                <Box align="center" margin="medium">
                <Text>No scholarship winners available.</Text>
                </Box>
            )}

                {/* Update Modal */}
                {updateModalOpen && (
                    <UpdateModal
                    data={selectedWinner}
                    onUpdate={handleUpdate}
                    onClose={() => setUpdateModalOpen(false)}
                    fields={[
                        { name: 'year', label: 'Year', type: 'text' },
                        { name: 'winnerName', label: "Winner's Name", type: 'text' },
                        { name: 'scholarshipName', label: 'Scholarship Name', type: 'text' },
                        // Add other fields as needed for updating
                    ]}
                    />
                )}
                {/* Modal for delete confirmation */}
                {showDeleteModal && selectedWinner && (
                    <DeleteModal name={selectedWinner.winnerName} onDelete={onDelete} onClose={() => setShowDeleteModal(false)}/>
                )}
                {/* Update success message */}
                {showUpdateSuccess && <SuccessMessage message="Scholarship winner successfully updated!" />}
                {/* Update error message */}
                {showUpdateError && <ErrorMessage message="Error occurred while updating." />}
                {/* Success message */}
                {showSuccessMessage && <SuccessMessage message={"Scholarship winner successfully deleted!"} />}

                    {/* Error message */}
                {showErrorMessage && <ErrorMessage message={"Error occurred while deleting."}/>}
            </Box>
        </Box>
    )
}
