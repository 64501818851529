import {useState} from 'react'
import {
    Box,
    Heading,
    Text,
    DataTable,
    Button,
    Spinner,
    CheckBox
} from 'grommet'
import {
    Trash,
    Copy
} from 'grommet-icons'
import { formatRelative } from 'date-fns';

// modals
import DeleteModal from '../../../components/deleteModal';
import ErrorMessage from '../../../components/ErrorMessage';
import SuccessMessage from '../../../components/SuccessMessage';

// Firebase
import { useCollection } from '../../../hooks/useCollection';
import { useFirestore } from '../../../hooks/useFirestore';


export default function NewsletterList() {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedNewsletter, setSelectedNewsletter] = useState(null);
    const { deleteDocument } = useFirestore('newsletter')
    const [trashHoverStates, setTrashHoverStates] = useState({});
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [successMessageText, setSuccessMessageText] = useState('')
    // Checkbox selection state and handlers
    const [selectAll, setSelectAll] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);

    const handleRowSelect = (rowId) => {
        setSelectedRows((prevSelected) =>
        prevSelected.includes(rowId) ? prevSelected.filter((id) => id !== rowId) : [...prevSelected, rowId]
        );
    };

    // Function to handle select all checkbox
    const onCheckAll = (event) => {
        if (event.target.checked) {
        setSelectedRows(sortedNewsletters.map((newsletter) => newsletter.id));
        } else {
        setSelectedRows([]);
        }
        setSelectAll(event.target.checked);
    };
    
    // Firestore hook for fetching
    const { documents: newsletters, loading } = useCollection('newsletter')

    // Sort the newsletters array based on the date in descending order (most recent at the top)
    const sortedNewsletters = newsletters ? [...newsletters].sort((a, b) => b.createdAt.toDate() - a.createdAt.toDate()) : [];

    const handleDeleteMessage = (newsletterId) => {
        const newsletterToDelete = newsletters.find((newsletter) => newsletter.id === newsletterId);
        setSelectedNewsletter(newsletterToDelete);
        setShowDeleteModal(true);
    };

    const onDelete = async() => {
        setSuccessMessageText('')
        // Delete the selected message
        if (selectedNewsletter) {
            try {
                await deleteDocument(selectedNewsletter.id);
                // Show the success message
                setSuccessMessageText('Successfully Deleted!')
                setShowSuccessMessage(true);
                // Hide the success message after 3 seconds
                setTimeout(() => {
                    setShowSuccessMessage(false);
                }, 3000);
            } catch (error) {
                // Show the error message
                setShowErrorMessage(true);
                // Hide the error message after 3 seconds
                setTimeout(() => {
                    setShowErrorMessage(false);
                }, 3000);
            }
        }
        // Close the modal
        setShowDeleteModal(false);
    }


    const handleCopyAllEmails = () => {
        setSuccessMessageText('')
        // Get the selected rows' emails
        const selectedEmails = dataTableData
            .filter((row) => selectedRows.includes(row.id))
            .map((row) => row.email)
            .join(', ');

        // Copy the selected emails to the clipboard
        navigator.clipboard.writeText(selectedEmails);

        // Show a success message or perform any other action if needed
        // Show the success message
        setSuccessMessageText('Selected emails copied')
        setShowSuccessMessage(true);
        setTimeout(() => {
        // close the modal
        setShowSuccessMessage(false);
        }, 2000)
    };

    // Transform messages into an array of objects suitable for DataTable
    const dataTableData = sortedNewsletters.map((newsletter) => ({
        id: newsletter.id,
        name: newsletter.name,
        email: newsletter.email,
        date: formatRelative(newsletter.createdAt.toDate(), new Date())
    }))
        
    const columns = [
        {
            property: 'checkbox',
            header: <CheckBox 
                        checked={selectAll}
                        indeterminate={selectedRows.length > 0 && selectedRows.length < newsletters.length}
                        onChange={onCheckAll}
                        aria-label="header checkbox"
                    />,
            align: 'center',
            sortable: false,
            render: (row) => (
                <CheckBox  
                    checked={selectedRows.includes(row.id)}
                    onChange={() => handleRowSelect(row.id)}
                />
            )
        },
        {
            property: 'name',
            header: <Text weight="bold">Name</Text>,
            primary: true,
            align: 'center'
        },
        {
            property: 'email',
            header: <Text weight="bold">Email</Text>,
            sortable: false,
            align: 'center'
        },
        {
            property: 'date',
            header: <Text weight="bold">Date</Text>,
            align: 'center'
        },
        {
            property: 'actions',
            header: <Text weight="bold">Actions</Text>,
            align: 'center',
            sortable: false,
            render: (row) => (
                <Button 
                    alignSelf='center'
                    plain
                    onMouseOver={() => setTrashHoverStates((prevStates) => ({ ...prevStates, [row.id]: true }))}
                    onMouseOut={() => setTrashHoverStates((prevStates) => ({ ...prevStates, [row.id]: false }))}
                    onClick={() => handleDeleteMessage(row.id)}
                >
                    <Trash color={trashHoverStates[row.id] ? 'status-critical' : undefined} />
                </Button>
            )
        }
    ]

    return (
        <Box width="xlarge" pad="medium" round background="light-1" elevation="medium">
            <Heading level={3} alignSelf="center">
                Newsletter List
            </Heading>
            <Box align="center" pad="large">
                {loading && (
                <Box align="center" justify="center" fill>
                    <Spinner size="large" />
                </Box>
                )}

                {!loading && newsletters && newsletters.length > 0 && (
                <DataTable
                    columns={columns}
                    data={dataTableData}
                    sortable // Add sortable option
                    sort={{ property: 'date', direction: 'desc' }} // Default sorting by date in descending order
                    primaryKey="id"
                    step={10} // Increase the step to show more rows at a time
                    paginate // Add pagination
                    pad="small"
                    background={{
                    header: 'light-3',
                    body: ['light-2', 'light-1'],
                    footer: 'light-3',
                    }}
                />
                )}

                {!loading && (!newsletters || newsletters.length === 0) && (
                <Box align="center" margin="medium">
                    <Text>No newsletters available.</Text>
                </Box>
                )}

                {/* Modal for delete confirmation */}
                {showDeleteModal && selectedNewsletter && (
                <DeleteModal
                    name={selectedNewsletter.name}
                    onDelete={onDelete}
                    onClose={() => setShowDeleteModal(false)}
                />
                )}
                {/* Success message */}
                {showSuccessMessage && <SuccessMessage message={successMessageText} />}

                {/* Error message */}
                {showErrorMessage && <ErrorMessage message="Error occurred while deleting." />}

                {/* Button to copy selected emails */}
                {!loading && newsletters && newsletters.length > 0 && (
                <Box align="end" margin={{ top: 'medium' }}>
                    <Button
                    color="brand"
                    hoverIndicator="control"
                    label="Copy Selected Emails"
                    icon={<Copy />}
                    onClick={handleCopyAllEmails}
                    primary
                    />
                </Box>
                )}
            </Box>
        </Box>
    )
}
