import { Box, MaskedInput, Text, RadioButtonGroup } from 'grommet';

const daysInMonth = (year, month) => new Date(year, month, 0).getDate();

const EventDate = ({ selectedDateOption, handleChangeDate, startDate, endDate, error, oneDateError }) => {

    const options = ['No Date', 'One Date/Deadline', 'Time Period'];
    
    

    const renderDateInput = () => {
        switch (selectedDateOption) {
        // No Date
        case 'No Date':
            return null;
        // One Date/Deadline
        case 'One Date/Deadline':
            
        return (
        <MaskedInput
                mask={[
                    {
                    length: [1, 2],
                    options: Array.from({ length: 12 }, (v, k) => String(k + 1).padStart(2, '0')),
                    regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
                    placeholder: 'mm',
                    },
                    { fixed: '/' },
                    {
                    length: [1, 2],
                    options: Array.from(
                        { length: daysInMonth(parseInt(startDate.split('/')[2], 10), parseInt(startDate.split('/')[0], 10)) },
                        (v, k) => String(k + 1).padStart(2, '0'),
                    ),
                    regexp: /^[1-2][0-9]$|^3[0-1]$|^0?[1-9]$|^0$/,
                    placeholder: 'dd',
                    },
                    { fixed: '/' },
                    {
                    length: 4,
                    options: Array.from({ length: 100 }, (v, k) => String(2019 - k)),
                    regexp: /^[1-2]$|^19$|^20$|^19[0-9]$|^20[0-9]$|^19[0-9][0-9]$|^20[0-9][0-9]$/,
                    placeholder: 'yyyy',
                    },
                ]}
                value={startDate}
                onChange={(event) => handleChangeDate(event.target.value, 'startDate')}
            />            
            );

        // Time Period
        case 'Time Period':
    

            return (
            <Box direction="row">
                <MaskedInput
                    mask={[
                        {
                        length: [1, 2],
                        options: Array.from({ length: 12 }, (v, k) => String(k + 1).padStart(2, '0')),
                        regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
                        placeholder: 'mm',
                        },
                        { fixed: '/' },
                        {
                        length: [1, 2],
                        options: Array.from(
                            { length: daysInMonth(parseInt(startDate.split('/')[2], 10), parseInt(startDate.split('/')[0], 10)) },
                            (v, k) => String(k + 1).padStart(2, '0'),
                        ),
                        regexp: /^[1-2][0-9]$|^3[0-1]$|^0?[1-9]$|^0$/,
                        placeholder: 'dd',
                        },
                        { fixed: '/' },
                        {
                        length: 4,
                        options: Array.from({ length: 100 }, (v, k) => String(2019 - k)),
                        regexp: /^[1-2]$|^19$|^20$|^19[0-9]$|^20[0-9]$|^19[0-9][0-9]$|^20[0-9][0-9]$/,
                        placeholder: 'yyyy',
                        },
                    ]}
                    value={startDate}
                    onChange={(event) => handleChangeDate(event.target.value, 'startDate')}
                />
                <Text alignSelf='center' margin={{ horizontal: 'small' }}>to</Text>
                <MaskedInput
                    mask={[
                        {
                        length: [1, 2],
                        options: Array.from({ length: 12 }, (v, k) => String(k + 1).padStart(2, '0')),
                        regexp: /^1[0,1-2]$|^0?[1-9]$|^0$/,
                        placeholder: 'mm',
                        },
                        { fixed: '/' },
                        {
                        length: [1, 2],
                        options: Array.from(
                            { length: daysInMonth(parseInt(endDate.split('/')[2], 10), parseInt(endDate.split('/')[0], 10)) },
                            (v, k) => String(k + 1).padStart(2, '0'),
                        ),
                        regexp: /^[1-2][0-9]$|^3[0-1]$|^0?[1-9]$|^0$/,
                        placeholder: 'dd',
                        },
                        { fixed: '/' },
                        {
                        length: 4,
                        options: Array.from({ length: 100 }, (v, k) => String(2019 - k)),
                        regexp: /^[1-2]$|^19$|^20$|^19[0-9]$|^20[0-9]$|^19[0-9][0-9]$|^20[0-9][0-9]$/,
                        placeholder: 'yyyy',
                        },
                    ]}
                    value={endDate}
                    onChange={(event) => handleChangeDate(event.target.value, 'endDate')}
                />
            </Box>
            );
        default:
            return null;
        }
    };

    return (
        <Box margin={{ vertical: 'xsmall' }}>
        <Box margin={{ vertical: 'small' }}>
            <RadioButtonGroup
            name="options"
            options={options}
            value={selectedDateOption}
            onChange={(e) => {
                handleChangeDate(e.target.value, 'selectedDateOption');
            }}
            direction="row"
            />
        </Box>
        {selectedDateOption === 'Time Period' && error && (
            <Text color="status-critical">{error}</Text>
        )}
        {selectedDateOption === 'One Date/Deadline' && oneDateError && (
            <Text color="status-critical">{oneDateError}</Text>
        )}
        {renderDateInput()}
        </Box>
    );
};

export default EventDate;
