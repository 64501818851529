import {useState} from 'react'
import {
    Box,
    Heading,
    Form,
    FormField,
    TextInput,
    Button,
    Select
} from "grommet"
import { useFirestore } from '../../../hooks/useFirestore';


// components
import SuccessMessageModal from '../../../components/SuccessMessageModal';
import ErrorMessageModal from '../../../components/ErrorMessageModal';

const scholarshipOptions = [
    "Technical/Vocational Scholarship",
    "Keith Skinner Memorial Scholarship",
    "Willie Allen Memorial Scholarship",
    "Other"
];

export default function ScholarshipsCreate() {
    const [year, setYear] = useState('')
    const [winnerName, setWinnerName] = useState('')
    const [scholarshipName, setScholarshipName] = useState('')
    const [customScholarship, setCustomScholarship] = useState('');
    const [selectedScholarship, setSelectedScholarship] = useState('') 
    // Add state variables to handle success and error modals
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)


    const { addDocument, response } = useFirestore('scholarshipWinners')

    const handleSubmit = async (event) => {
        event.preventDefault();
        
        // Determine the scholarship name based on user input
        const scholarshipName = selectedScholarship === 'Other' ? customScholarship : selectedScholarship;

        // Perform some action with the scholarship name, such as logging or saving
        console.log('Submitted scholarship name:', scholarshipName);


        setIsLoading(true)
         // Check if the required fields are empty
        if (!year || !scholarshipName) {
        setErrorMessage('Please fill in all the required fields.');
        setShowErrorModal(true);
        setIsLoading(false)
        return; // Prevent further execution of the function
        }

        const scholarshipWinner = {
            year,
            winnerName,
            scholarshipName,
        }

        await addDocument(scholarshipWinner);
        if (response.error) {
            setIsLoading(false)
            setErrorMessage('An error occurred while submitting the form. Please try again later.')
            setShowErrorModal(true);
        }  else {
            setSuccessMessage('Successfuly created scholarship winner!')
            setShowSuccessModal(true);
            // reset forms
            setYear('');
            setWinnerName('');
            setScholarshipName('');
            setSelectedScholarship('');
            setCustomScholarship('');
            setIsLoading(false)
        }
        };

        const handleReset = (event) => {
            event.preventDefault();

            // reset forms
            setYear('')
            setWinnerName('')
            setScholarshipName('')
            setSelectedScholarship('');
            setCustomScholarship('');
        }

        const handleScholarshipChange = (event) => {
            setSelectedScholarship(event.option);
        };
        const handleCustomScholarshipChange = (event) => {
            setCustomScholarship(event.target.value);
        };

    return (
        <Box width="xlarge" pad="medium" round background="light-1" elevation="medium">
            <Heading level={3}  alignSelf='center'>Create New Scholarship</Heading>
            <Form onSubmit={handleSubmit} onReset={handleReset}>
                <FormField label="Year"  >
                    <Select
                        options={[...Array(5).keys()].map((_, index) => { // Show a range of 10 years from the current year
                            const currentYear = new Date().getFullYear();
                            return (currentYear - index).toString();
                        })}
                        placeholder="Select Year"
                        value={year}
                        onChange={({ option }) => setYear(option)}
                        
                    />
                </FormField>
                <FormField label="Winner's Name" >
                    <TextInput
                        value={winnerName}
                        onChange={(e) => setWinnerName(e.target.value)}
                        required
                        placeholder="Enter scholarship winner's name"
                    />
                </FormField>
                <FormField label="Select or Add Scholarship">
                    <Select
                        options={scholarshipOptions}
                        value={selectedScholarship}
                        onChange={handleScholarshipChange}
                        placeholder="Select a scholarship"
                    />
                    </FormField>
                    {selectedScholarship === 'Other' && (
                    <FormField label="Custom Scholarship Name">
                        <TextInput
                        value={customScholarship}
                        onChange={handleCustomScholarshipChange}
                        placeholder="Enter custom scholarship name"
                        />
                </FormField>
                )}  
                {!isLoading && (
                    <Box direction="row" justify="end" gap="medium">
                    <Button type="reset" label="Reset" />
                    <Button type="submit" label="Save" primary />
                    </Box>
                )}
                {isLoading && (
                    <Box direction="row" justify="end" gap="medium">
                    <Button type="reset" disabled label="Reset" />
                    <Button type="submit" disabled label="Loading..." primary />
                </Box>
                )}
                
            </Form>
            {/* Show the success modal when showSuccessModal is true */}
            {showSuccessModal && (
                <SuccessMessageModal
                    message={successMessage}
                    onClose={() => setShowSuccessModal(false)}
                />
            )}

            {/* Show the error modal when showErrorModal is true */}
            {showErrorModal && (
                <ErrorMessageModal
                    message={errorMessage}
                    onClose={() => setShowErrorModal(false)}
                />
            )}
        </Box>
    )
}
