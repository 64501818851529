import React from 'react'
import {
    Box,
    Heading,
    Button,
    Text,
    Card,
    CardBody,
    CardHeader,
    Spinner
} from 'grommet'
import { useHistory, Link } from "react-router-dom"
import { useCollection } from '../../../hooks/useCollection';

export default function ProjectList() {
    // Firestore hook for fetching projects
    const { documents: projects, loading } = useCollection('projects')
    
    // Sort projects by creation date in descending order
    const sortedProjects = projects ? [...projects].sort((a, b) =>  b.createdAt.toDate() - a.createdAt.toDate()) : [];

    const history = useHistory()
    
    const handleClick = (e) => {
        e.preventDefault();
        history.push('/create-project')
    }


    return (
        //To-Do List
        <Box pad={{ horizontal: 'large', top: 'large' }} align='center'>
            <Heading level={2}>Project List</Heading>
            <Box margin={{ bottom: 'medium' }}>
                <Button
                color='brand'
                hoverIndicator="control"
                primary
                label={<Text weight='bold'>New Project</Text>}
                onClick={handleClick}
                />
            </Box>

            <Box gap='medium'  align='center'>
                {loading && (
                    <Box align="center" justify="center" fill>
                        <Spinner size="large" />
                    </Box>
                )}
                {!loading && projects && projects.length > 0 && (
                    <Box width="medium">
                    {sortedProjects.map((project) => (
                        <Box margin={{vertical: 'small'}}>
                        <Box alignSelf='center' margin={{bottom: "small"}}>
                            <Text textAlign='center'>Click the box for more information</Text>
                        </Box>

                        <Link key={project.id} to={`/projects/${project.id}`} style={{ textDecoration: 'none' }}>
                            <Card background='light-1' elevation='medium' round='small'>
                            <CardHeader pad='medium' background='brand' round={{ corner: 'top', size: 'small' }} justify="center"  align='center' > 
                                <Text size='large' textAlign='center' weight='bold' color='white'>
                                {project.projectName}
                                </Text>
                            </CardHeader>
                            <CardBody pad='medium' justify="center">
                                <Text textAlign='center'>Due by {project.projectDueDate.toDate().toDateString()}</Text>
                                <Box justify='center' align='start'>
                                <ul>
                                    {project.assignedUsersList.map((user) => (
                                    <li key={user.displayName}>
                                        <Text>{user.displayName}</Text>
                                    </li>
                                    ))}
                                </ul>
                                </Box>
                            </CardBody>
                            </Card>
                        </Link>
                        </Box>
                        ))}
                    </Box>
                )}
                {!loading && (!projects || projects.length === 0) && (
                <Box align="center" margin="medium">
                    <Text>No projects yet!</Text>
                </Box>
                )}
            </Box>
        </Box>
    )
}
