import { useState } from "react";
import { projectStorage } from "../firebase/config";

export const useFeaturedImage = () => {
    const [error, setError] = useState(null);
    const [isPending, setIsPending] = useState(false);

    const uploadImage = async (image) => {
        setError(null);
        setIsPending(true);

        // Create a reference to the storage bucket path where the image will be uploaded
        const uploadPath = `featuredImages/${image.name}`;
        const storageRef = projectStorage.ref(uploadPath);

        try {
        // Upload the image to Firebase Storage
        const uploadTaskSnapshot = await storageRef.put(image);

        // Get the download URL of the uploaded image
        const downloadURL = await uploadTaskSnapshot.ref.getDownloadURL();

        setIsPending(false);
        return downloadURL;
        } catch (err) {
        setError("Error uploading image. Please try again later.");
        setIsPending(false);
        return null;
        }
    };

    return { uploadImage, error, isPending };
};
